import PropTypes from 'prop-types'
import React from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'

export const Modal = props => {
  const {
    shouldShow,
    onClose,
    children,
    isFullsSreenOnMobile
  } = props

  return (
    <AnimatedModal
      shouldShow={shouldShow}
      isFullsSreenOnMobile={isFullsSreenOnMobile}
      onClick={onClose}
    >
      {children}
    </AnimatedModal>
  )
}
Modal.propTypes = {
  shouldShow: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
}
export default Modal

import PropTypes from 'prop-types'
import React from 'react'
import { useTransition, animated } from 'react-spring'

import { AnimatedOverlay } from './AnimatedOverlay'

import { block } from 'utils/classnames'

import './AnimatedModal.sass'

const b = block.with('animated-modal')

const slideInTransition = {
  from: { opacity: 0, transform: 'translate3d(-50%, 10%)' },
  enter: { opacity: 1, transform: 'translate3d(-50%, 0)' },
  leave: { opacity: 0, transform: 'translate3d(-50%, 10%)' }
}

export const AnimatedModal = props => {
  const {
    shouldShow = false,
    noScroll = true,
    className = '',
    children,
    isFullsSreenOnMobile = false,
    onClick
  } = props

  const transitions = useTransition(shouldShow, null, slideInTransition)

  return transitions
    .map(({ item, key, props }) => (
      item
        ? (
          <div className={b({ isFullsSreenOnMobile })} key={key}>
            <AnimatedOverlay
              onClick={onClick}
              shouldShow={shouldShow}
              noScroll={noScroll}
            />
            <animated.div
              className={`${b('content')} ${className}`.trim()}
              style={props}
            >
              {children}
            </animated.div>
          </div>
        )
        : null
    ))
}
AnimatedModal.propTypes = {
  shouldShow: PropTypes.bool,
  noScroll: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  isFullsSreenOnMobile: PropTypes.bool,
  onClick: PropTypes.func
}
export default AnimatedModal

import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './checkbox.sass'

export const Checkbox = ({
  required = false,
  disabled = false,
  value = false,
  onChange,
  name = '',
  autoFocus = false,
  placeholder,
  label
}) => (
  <div className={block('input-checkbox')}>
    <label htmlFor={name}>
      <input
        type='checkbox'
        name={name}
        required={required}
        disabled={disabled}
        checked={value === true}
        onChange={e => onChange && onChange(e.target.checked)}
        autoFocus={autoFocus}
        id={name}
      />
      <span className={block('input-checkbox', 'custom-checkbox')} />
      <div className={block('input-checkbox', 'custom-text')}>{label || placeholder}</div>
    </label>
  </div>
)

Checkbox.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.node,
  autoFocus: PropTypes.bool
}

export default Checkbox

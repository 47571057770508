import React, {
  useCallback,
  useState,
  useRef
} from 'react'

import { block } from 'utils/classnames'
import { PreventButton } from 'utils/preventButton'
import { stripePromise } from 'config/stripe'

import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'

import Checkbox from 'components/form/Checkbox'

import './CreditCardForm.sass'

const b = block.with('credit-card-form')

const FAILED_TO_LOAD_STRIPE_MESSAGE = 'Oups ! Il semble qu\'une extension sur votre navigateur ou votre pare-feu bloque l\'affichage de ce formulaire. Essayez de les désactiver ou réessayer depuis un autre navigateur ou un autre réseau.'

const CARD_FORM_ERROR_MESSAGES = {
  incomplete_number: 'Vous n\'auriez pas oublié 2 chiffres ?',
  invalid_number: 'Vous n\'auriez pas mélangé 2 chiffres ?',
  invalid_expiry_month_past: 'On dirait que votre carte est expirée !',
  invalid_expiry_year_past: 'On dirait que votre carte est expirée !'
}
const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '15px',
      fontWeight: 'normal',
      color: '#000',
      letterSpacing: '0.2px',

      '::placeholder': {
        color: '#A49FAD',
        fontWeight: 'normal',
        letterSpacing: '0.2px'
      }
    }
  }
}

export const CreditCardForm = props => {
  const {
    hasSubmit = true,
    hasSave = true,
    onSubmit,
    submitLabel
  } = props

  const stripe = useStripe()
  const elements = useElements()

  const [shouldSave, setShouldSave] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const clickedRef = useRef(false)

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      if (!stripe || clickedRef.current) {
        return
      }
      clickedRef.current = true
      const response = await stripe
        .createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)
        })

      const {
        error,
        paymentMethod
      } = response

      if (error) {
        setErrorMessage(
          CARD_FORM_ERROR_MESSAGES[error?.code] || error.message
        )
        return
      }
      paymentMethod?.card && onSubmit && onSubmit({
        ...paymentMethod.card,
        shouldSave,
        paymentMethodId: paymentMethod.id,
        expirationDate: [
          paymentMethod.card.exp_month,
          paymentMethod.card.exp_year
        ]
      })
    },
    [stripe, shouldSave]
  )

  const handleCardChange = useCallback(e => {
    const { error } = e
    if (error) {
      setErrorMessage(CARD_FORM_ERROR_MESSAGES[error?.code] || error.message)
      clickedRef.current = false
    } else {
      setErrorMessage('')
    }
  }, [])

  const handleSaveChange = useCallback(shouldSave => {
    setShouldSave(shouldSave)
  }, [])

  const handleCardReady = useCallback(e => e.focus(), [])

  return stripe
    ? (
      <form className={b()} onSubmit={handleSubmit}>
        <div className={b('card-field')}>
          <CardElement
            onChange={handleCardChange}
            onReady={handleCardReady}
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
        <p className={b('error')}>{errorMessage}</p>
        {
          hasSave
            ? (
              <div className={b('save-field')}>
                <Checkbox
                  name='shouldSave'
                  value={shouldSave}
                  onChange={handleSaveChange}
                  label={(
                    <span className={b('save-field-label')}>
                      <strong>
                      RAPIDE COMME L'ÉCLAIR
                      </strong>
                      <br />
                    Enregistrez votre carte de paiement pour vos prochaines commandes
                    </span>
                  )}
                />
              </div>
            )
            : null
        }
        {
          hasSubmit
            ? (
              <PreventButton
                isForm
                className={b('submit')}
                disabled={!stripe || clickedRef.current}
              >
                {submitLabel}
              </PreventButton>
            )
            : null
        }
      </form>
    ) : (
      <p className={b('load-stripe-error')}>{FAILED_TO_LOAD_STRIPE_MESSAGE}</p>
    )
}

const StripedCreditCardForm = props => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm {...props} />
    </Elements>
  )
}

export default StripedCreditCardForm
